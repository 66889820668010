import React from "react";
import SEO from "../components/seo";

import * as styles from "./404.module.css";

const NotFoundPage = () => (
  <div className={styles.root}>
    <SEO title="404: Not found" />
    <h1 className={styles.h1}>Not found</h1>
    <p>This page does not exist.</p>
  </div>
);

export default NotFoundPage;
